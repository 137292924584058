@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/proxima-nova/proxima-nova-light.otf);
}

@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/proxima-nova/proxima-nova-regular.otf);
}

@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/proxima-nova/proxima-nova-bold.otf);
}

@import "~@angular/material/theming";

.box-search-icon {
  display: none;
}

.card-high-priority {
  border-left: 8px solid #d22630;
}

.card-average-priority {
  border-left: 8px solid #ff6600;
}

.card-low-priority {
  border-left: 8px solid #003087;
}

.mat-select-panel {
  margin-left: 10px;
  //margin-top: 40px;
}

$general-typography: mat-typography-config($font-family: "ProximaNova",
  );

@include mat-core($general-typography);

pre {
  font-family: ProximaNova !important;
}

#filtro-selecionado-popup,
#meus-filtros-popup {
  padding-left: 0px;
  padding-right: 0px;
}

.filter-container {
  max-height: calc(100vh - 200px);
  overflow: auto;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  height: auto;
}

.mat-dialog-container {
  width: 100%;
  max-width: 88vw;
}

.filter-header {
  padding-bottom: 16px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #eef0f3;
}

.filter-footer {
  padding-top: 16px;
  border-top: 1px solid #eef0f3;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: ProximaNova !important;
  color: #5b5c60;
}

.mat-tab-group,
.mat-expansion-panel-content,
.mat-radio-button,
.mat-select,
.mat-checkox,
.mat-ipnut,
.mat-button {
  font-family: ProximaNova !important;
  color: #5b5c60;
}

html {
  background: url(./assets/images/background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.container {
  padding-top: 16px;
  min-width: 300px;
}

.mat-card {
  font-family: ProximaNova !important;
  width: 95%;
  padding: 50px !important;
  box-shadow: 0px 2px 80px rgba(37, 38, 49, 0.05) !important;
  border-radius: 4px;

  &.center {
    text-align: center;
    font-weight: bold;
  }
}

.submit {
  border-radius: 24px !important;
  background-color: #003087;
  padding: 8px 32px;

  &.secondy {
    background-color: #fff;
    border: 1px solid #003087;
    color: #003087;
  }
}

.btn-outline {
  box-shadow: none !important;
  border-radius: 24px !important;
  border: 1px solid #003087 !important;
  color: #003087;
}

.clear-filter {
  cursor: pointer;
  color: #005afe;
  font-size: 14px;
}

.field-disabled {
  opacity: 0.4;
  cursor: unset !important;
}

.field {
  width: 70%;
}

.pop-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  color: #5b5c60;
}

.pop-sub-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #5b5c60;
}

.screen-header {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  color: #5b5c60;
  margin-bottom: 24px;
}

.total-info {
  font-size: 14px;
  font-weight: normal;
  line-height: 150%;
}

.arrow-drop-down {
  font-size: 24px !important;
  color: rgba(0, 0, 0, 0.54);
}

.auto-complete .mat-form-field-suffix {
  top: 0 !important;
}

.primary-color {
  color: #003087;
}

.mat-expansion-panel-header {
  font-family: ProximaNova !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #003087 !important;
}

.green-snackbar {
  font-family: ProximaNova !important;
  background: #4f9e52;
  color: #fff;
}

.green-snackbar button {
  background-color: #4f9e52;
  color: #fff !important;
  border: none;
}

.yellow-snackbar {
  font-family: ProximaNova !important;
  background: #f68f15;
  color: #fff;
}

.yellow-snackbar button {
  background-color: #f68f15;
  color: #fff !important;
  border: none;
}

.red-snackbar {
  font-family: ProximaNova !important;
  background: #f46363;
  color: #fff;
}

.red-snackbar button {
  background-color: #f46363;
  color: #fff !important;
  border: none;
}

.mat-snack-bar-container.green-snackbar,
.mat-snack-bar-container.red-snackbar {
  max-width: 100%;
}

button {
  box-shadow: none !important;
}

.tela {
  h2.titulo-tela {
    margin: 0;
    font-size: 20px;
    color: #5b5c60;
    font-weight: 700;
    line-height: 24px;
  }

  .eng-table {
    margin-top: 30px;
    max-height: 480px;
    min-width: 800px;
    overflow: auto;
    box-shadow: initial;

    .distancia-overflow {
      height: 100px;
    }

    table {
      position: relative;
      margin-top: -5px;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 5px;
      min-width: 1400px;

      .center {
        text-align: center;
      }

      .right {
        text-align: right;
      }

      .left {
        text-align: left;
      }

      tr.grid-header {
        th {
          border-bottom: 0;
          color: #5b5c60;
          background: #eef0f3;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          height: 42px;
          font-size: 14px;
        }

        & th:first-child {
          border-radius: 8px 0 0 8px;
        }
      }

      tr.grid-row {
        box-shadow: 1px 1px 3px #eef0f3;
        min-height: initial;
        cursor: initial;

        td {
          border-top: 1px solid #eef0f3;
          border-bottom: 1px solid #eef0f3;
          font-size: 14px;
        }

        & td:first-child {
          border-radius: 8px 0 0 8px;
          border-left: 1px solid #eef0f3;
        }

        & td:last-child {
          border-radius: 0 8px 8px 0;
          border-right: 1px solid #eef0f3;
        }
      }

      tr.grid-row:hover {
        box-shadow: 1px 1px 3px #e6e8eb;
      }

      tr.grid-row-normal {
        td {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      .eng-field-grid {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 10px;
      }

      .text-field {
        box-sizing: border-box;
        height: 34px;
        border-radius: 4px;
        padding: 8px;
        border: 1px solid #d1d1d1;
        width: 100%;
      }
    }
  }

  .action {
    margin: 15px 0;
  }

  .btn-save {
    font-weight: bold;

    &.disabled {
      background-color: #b8bac2 !important;
      color: #fff;
    }
  }

  .btn-back {
    background-color: #d22630 !important;
    color: #fff;
    border-radius: 24px !important;
    font-weight: bold;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cdk-drag-placeholder {
  background: rgb(204, 204, 204) !important;
  border: 1px solid #cfcfcf;
}

.cdk-drag-preview {
  display: flex;
  justify-content: initial;
  align-items: center;
  background: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: 1px 1px 3px #eef0f3;
  border-radius: 5px;
  min-height: initial !important;

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }

  td {
    border: 0 !important;
    font-size: 14px;
  }

  td.data {
    box-sizing: border-box;
    margin-right: 10px !important;
  }

  .eng-field-grid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 10px;
  }

  .text-field {
    box-sizing: border-box;
    height: 34px;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid #d1d1d1;
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  label {
    font-weight: bold;
  }

  &.v-center {
    justify-content: center;
  }

  &.h-center {
    align-items: center;
  }

  &.mb-5 {
    margin-bottom: 24px;
  }

  &.divider {
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eef0f3;
  }

  .col {
    display: flex;
    flex-direction: column;

    &--1 {
      @extend .col;
      flex: 1 1 100%;
    }

    &--2 {
      @extend .col;
      flex: 1 1 50%;
    }

    &--3 {
      @extend .col;
      flex: 1 1 33%;
    }

    &--4 {
      @extend .col;
      flex: 1 1 25%;
    }
  }
}

.modal-content {
  display: block;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  height: 100%;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-icon {
      cursor: pointer;
    }

    h5 {
      margin: 0;
      font-weight: bold;
      font-size: 28px;
      color: #5b5c60;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 24px 0;
    max-height: calc(70vh - 110px);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(184 186 194 / 50%);
      border-radius: 8px;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    app-button {
      margin-left: 12px;
    }
  }
}

.noOutline,
.noOutline:focus,
.noOutline:active {
  outline: none;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.modal-dialog {
  max-height: calc(100vh - 120px);
}